<template>
  <div class="about-view">
    <div class="main-panel">
      <PageHeaderKiosk/>
      <div class="text-center">
        <h3>About</h3>
        <h1>{{appTitle}}</h1>
      </div>
      <div class="menu-list container-fluid">
        <div class="text-muted mt-2">Version:</div>
        <div class="mt-0"><h3>{{this.$appConfig.appVersion}}</h3></div>
        <div class="text-muted mt-2">Copyright:</div>
        <div class="mt-0"><h3>&#169; Kilburn Live, LLC. 2021<br/> All rights reserved.</h3></div>
        <!-- <button type="button" class="btn btn-primary btn-large btn-block my-4" @click="go(-1)"><h3>Close</h3></button> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeaderKiosk from '@/components/PageHeaderKiosk.vue'

export default {
  name: 'AboutView',
  components: {
    PageHeaderKiosk,
  },
  data() {
    return {
      appTitle: this.$appConfig.appTitle,
    }
  },
  methods :{
  }
}
</script>